.header {
  .logo {
    float: left;
    font-size: 30px;
    text-align: center;
  }
}

.menu {
  float: right;
  height: 100%;

  li {
    float: left;
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #333;
    font-weight: 500;
    cursor: pointer;
    position: relative;
  }

  li.on {
    color: #9a04eb;

    &::after {
      content: '';
      position: absolute;
      bottom: 25px;
      left: 35px;
      width: 50px;
      height: 2px;
      background: #9a04eb;
    }
  }

  li:hover {
    color: #9a04eb;
  }
}

.banner {
  position: relative;
  text-align: center;

  img {
    width: 100%;
  }

  .tips {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    color: #fff;
    font-weight: 400;
  }
}

.finance-mode,
.business-mode,
.insurance-mode,
.about-mode,
.contact-mode {
  color: #999;
  text-align: center;

  .title {
    color: #333;
  }

  .tips {
    color: #666;
  }

  img {
    width: 100%;
  }

  .about-banner {
    display: inline-block;
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    .banner-list {
      position: absolute;
      left: 50%;
      top: 50%;

      li {
        width: calc(100% / 3);
        float: left;
        color: #fff;
      }
    }
  }

  .contact-info {
    margin: 50px auto;
    display: inline-block;

    .logo {
      text-align: left;

      p {
        color: #333;
      }
    }

    .list {
      float: right;
      text-align: left;
    }
  }
}

.insurance-mode {
  .item {
    width: 25%;
    float: left;
    color: #666;
  }
}

.about-mode {
  background: #f7f6f8;

  .superiority {
    display: inline-block;
    width: 100%;

    .item {
      width: 33.3333333%;
      float: left;
      color: #666;
      background: #fff;
      border-radius: 7px;
      border: 1px solid #ddd;
    }
  }
}

.business-mode {
  padding: 0 !important;
  display: inline-block;
  width: 100%;

  .business-warp {
    position: absolute;

    .nav-title,
    .info,
    .tips {
      color: #fff;
    }
  }
}

@media screen and (min-width: 1300px) {
  .warp {
    max-width: 1200px;
  }

  .header {
    line-height: 88px;
    height: 88px;

    .logo {
      width: 130px;
      height: 40px;
      margin: 23px 0;
    }

    .menu {
      font-size: 18px;

      li {
        width: 120px;
      }
    }
  }

  .banner {
    p {
      margin: 0;
    }

    .tips {
      width: 560px;
      height: 110px;
      margin: -55px 0 0 -280px;
      font-size: 20px;

      .name {
        font-size: 60px;
      }

      .info {
        font-size: 30px;
        margin: -5px 0 10px;
      }
    }
  }

  .finance-mode,
  .business-mode,
  .insurance-mode,
  .about-mode,
  .contact-mode {
    padding: 60px 0;
    font-size: 16px;
    position: relative;

    .info {
      font-size: 27px;
      color: #bbb;
    }

    .tips {
      margin: 5px 0 89px 0;
      line-height: 30px;
      padding: 0 140px;
    }
  }

  .business-warp {
    width: 70%;
    top: 0;
    left: 50%;
    margin: 0 0 0 -35%;
    padding: 40px 0;
  }

  .insurance-mode {
    .service {
      .tilte {
        font-size: 18px;
      }

      .list {
        margin: 60px 0 0;

        img {
          width: 41px;
          height: 41px;
          margin-bottom: 25px;
        }

        .item {
          font-size: 16px;
        }
      }
    }

    .diagram {
      padding: 80px 60px 0;
      text-align: center;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 576px;
        height: 486px;
        margin: 50px 0 0;
      }
    }
  }

  .about-mode {
    padding: 60px 0 0;

    .superiority {
      .list {
        margin: 30px 140px 80px;

        .item {
          width: 230px;
          height: 160px;
          margin: 0 110px 0 0;
          padding: 30px 0 0;
          box-sizing: border-box;

          img {
            width: 68px;
            height: 48px;
            margin-bottom: 20px;
          }

          font-size: 16px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .ecosphere {
      padding: 80px 60px 0;
      margin: 0 0 80px 0;
      text-align: center;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 300px;
        height: 300px;
        margin: 50px 0 0;
      }
    }
  }

  .contact-info {
    width: 900px;

    .logo {
      float: left;
      margin: 20px 0;

      p {
        font-size: 20px;
        margin: 10px 0;
      }

      img {
        width: 130px;
        height: 40px;
      }
    }

    .list {
      width: 320px;

      li {
        width: 100%;
        font-size: 14px;
        color: #333;
        display: inline-block;
        line-height: 40px;

        div {
          float: left;
          line-height: 40px;
          font-size: 14px;
        }

        .icon {
          width: 30px;

          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }

        .name {
          width: 80px;
        }

        .content {
          width: 210px;
        }
      }
    }
  }

  .nav-title {
    font-size: 32px;
  }
}

@media screen and (min-width: 750px) and (max-width: 1300px) {
  .warp {
    max-width: 800px;
  }

  .header {
    line-height: 76px;
    height: 80px;

    .logo {
      width: 100px;
      height: 36px;
      margin: 17px;
    }

    .menu {
      font-size: 14px;

      li {
        width: 90px;
      }

      li.on {
        &::after {
          bottom: 23px;
          left: 24px;
          width: 40px;
          height: 2px;
        }
      }
    }
  }

  .banner {
    p {
      margin: 0;
    }

    .tips {
      width: 560px;
      height: 110px;
      margin: -55px 0 0 -280px;
      font-size: 16px;

      .name {
        font-size: 30px;
      }

      .info {
        font-size: 20px;
        margin: -5px 0 10px;
      }
    }
  }

  .finance-mode,
  .business-mode,
  .insurance-mode,
  .about-mode,
  .contact-mode {
    padding: 60px 0;
    font-size: 14px;
    position: relative;

    .info {
      font-size: 18px;
      color: #bbb;
    }

    .tips {
      margin: 5px 0 30px 0;
      line-height: 30px;
      padding: 0 120px;
    }
  }

  .business-warp {
    width: 90%;
    top: 0;
    left: 50%;
    margin: 0 0 0 -45%;
    padding: 30px 0;

    img {
      padding: 0 20%;
      box-sizing: border-box;
    }
  }

  .insurance-mode {
    .service {
      .tilte {
        font-size: 18px;
      }

      .list {
        margin: 60px 0 0;

        img {
          width: 41px;
          height: 41px;
          margin-bottom: 25px;
        }

        .item {
          font-size: 16px;
        }
      }
    }

    .diagram {
      padding: 80px 60px 0;
      text-align: center;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 376px;
        height: 286px;
        margin: 50px 0 0;
      }
    }
  }

  .about-mode {
    padding: 60px 0 0;

    .superiority {
      .list {
        margin: 30px 122px 80px;

        .item {
          width: 140px;
          height: 80px;
          margin: 0 50px 0 0;
          padding: 15px 0 0;
          box-sizing: border-box;

          img {
            width: 40px;
            height: 28px;
            margin-bottom: 5px;
          }

          font-size: 12px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .ecosphere {
      padding: 80px 60px 0;
      margin: 0 0 80px 0;
      text-align: center;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 200px;
        height: 200px;
        margin: 50px 0 0;
      }
    }
  }

  .contact-info {
    width: 600px;

    .logo {
      float: left;
      margin: 20px 0;

      p {
        font-size: 16px;
        margin: 10px 0;
      }

      img {
        width: 110px;
        height: 30px;
      }
    }

    .list {
      width: 320px;

      li {
        width: 100%;
        font-size: 12px;
        color: #333;
        display: inline-block;
        line-height: 40px;

        div {
          float: left;
          line-height: 40px;
          font-size: 12px;
        }

        .icon {
          width: 30px;

          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }

        .name {
          width: 80px;
        }

        .content {
          width: 210px;
        }
      }
    }
  }

  .nav-title {
    font-size: 24px;
  }
}

@media screen and (min-width: 550px) and (max-width: 750px) {
  .warp {
    max-width: 375px;
  }

  .header {
    line-height: 51px;
    height: 55px;

    .logo {
      width: 50px;
      height: 20px;
      margin: 12px 20px;
    }

    .menu {
      font-size: 12px;

      li {
        width: 55px;
      }

      li.on {
        &::after {
          bottom: 10px;
          left: 8px;
          width: 40px;
          height: 2px;
        }
      }
    }
  }

  .banner {
    p {
      margin: 0;
    }

    .tips {
      width: 375px;
      height: 110px;
      margin: -40px 0 0 -190px;
      font-size: 12px;

      .name {
        font-size: 20px;
      }

      .info {
        font-size: 14px;
        margin: -5px 0 10px;
      }
    }
  }

  .finance-mode,
  .business-mode,
  .insurance-mode,
  .about-mode,
  .contact-mode {
    padding: 30px 0;
    font-size: 14px;
    position: relative;

    .info {
      font-size: 14px;
      color: #bbb;
    }

    .tips {
      margin: 5px 0 29px 0;
      line-height: 24px;
      padding: 0 10px;
    }
  }

  .finance-mode {
    img {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  .business-warp {
    width: 90%;
    top: 0;
    left: 50%;
    padding: 20px 0;
    margin: 0 0 0 -45%;

    .tips {
      font-size: 10px;
      line-height: 20px;
      margin: 15px 0 5px;
    }

    img {
      padding: 5px 80px 0;
      box-sizing: border-box;
    }
  }

  .insurance-mode {
    .service {
      .tilte {
        font-size: 14px;
      }

      .list {
        margin: 30px 0 0;

        img {
          width: 31px;
          height: 31px;
          margin-bottom: 15px;
        }

        .item {
          font-size: 12px;
        }
      }
    }

    .diagram {
      padding: 40px 30px 0;
      text-align: center;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 196px;
        margin: 30px 0 0;
      }
    }
  }

  .about-mode {
    padding: 30px 0 0;

    .superiority {
      .list {
        margin: 30px 20px 30px;

        .item {
          width: 100px;
          height: 80px;
          margin: 0 15px 0 0;
          padding: 15px 0 0;
          box-sizing: border-box;

          img {
            width: 30px;
            margin-bottom: 5px;
          }

          font-size: 12px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .ecosphere {
      padding: 40px 30px 0;
      margin: 0 0 80px 0;
      text-align: center;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 180px;
        height: 180px;
        margin: 30px 0 0;
      }
    }
  }

  .contact-info {
    width: 375px;

    .logo {
      text-align: center;
      margin: 0 auto;
      display: table;

      p {
        font-size: 16px;
        margin: 10px 0;
      }

      img {
        width: 110px;
        height: 30px;
      }
    }

    .list {
      display: table;
      margin: 20px auto 0;
      width: 300px;

      li {
        width: 100%;
        font-size: 12px;
        color: #333;
        display: inline-block;
        line-height: 40px;

        div {
          float: left;
          line-height: 40px;
          font-size: 12px;
        }

        .icon {
          width: 30px;

          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }

        .name {
          width: 60px;
        }

        .content {
          width: 170px;
        }
      }
    }
  }

  .nav-title {
    font-size: 20px;
  }
}

@media screen and (min-width: 0) and (max-width: 550px) {
  .warp {
    max-width: 375px;
  }

  .header {
    line-height: 51px;
    height: 55px;

    .logo {
      width: 50px;
      height: 20px;
      margin: 12px 20px;
    }

    .menu {
      font-size: 12px;

      li {
        width: 55px;
      }

      li.on {
        &::after {
          bottom: 10px;
          left: 8px;
          width: 40px;
          height: 2px;
        }
      }
    }
  }

  .banner {
    p {
      margin: 0;
    }

    .tips {
      width: 375px;
      height: 110px;
      margin: -40px 0 0 -190px;
      font-size: 12px;

      .name {
        font-size: 20px;
      }

      .info {
        font-size: 14px;
        margin: -5px 0 10px;
      }
    }
  }

  .finance-mode,
  .business-mode,
  .insurance-mode,
  .about-mode,
  .contact-mode {
    padding: 30px 0;
    font-size: 14px;
    position: relative;

    .info {
      font-size: 14px;
      color: #bbb;
    }

    .tips {
      margin: 5px 0 29px 0;
      line-height: 24px;
      padding: 0 10px;
    }
  }

  .finance-mode {
    img {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  .business-warp {
    width: 90%;
    top: 0;
    left: 50%;
    padding: 5px 0;
    margin: 0 0 0 -45%;

    .tips {
      font-size: 10px;
      line-height: 14px;
      margin: 0 0 5px;
    }

    img {
      padding: 0 80px;
      box-sizing: border-box;
    }
  }

  .insurance-mode {
    .service {
      .tilte {
        font-size: 14px;
      }

      .list {
        margin: 30px 0 0;

        img {
          width: 31px;
          height: 31px;
          margin-bottom: 15px;
        }

        .item {
          font-size: 12px;
        }
      }
    }

    .diagram {
      padding: 40px 30px 0;
      text-align: center;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 196px;
        margin: 30px 0 0;
      }
    }
  }

  .about-mode {
    padding: 30px 0 0;

    .superiority {
      .list {
        margin: 30px 20px 30px;

        .item {
          width: 100px;
          height: 80px;
          margin: 0 15px 0 0;
          padding: 15px 0 0;
          box-sizing: border-box;

          img {
            width: 30px;
            margin-bottom: 5px;
          }

          font-size: 12px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .ecosphere {
      padding: 40px 30px 0;
      margin: 0 0 80px 0;
      text-align: center;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 180px;
        height: 180px;
        margin: 30px 0 0;
      }
    }
  }

  .contact-info {
    width: 375px;

    .logo {
      text-align: center;
      margin: 0 auto;
      display: table;

      p {
        font-size: 16px;
        margin: 10px 0;
      }

      img {
        width: 110px;
        height: 30px;
      }
    }

    .list {
      display: table;
      margin: 20px auto 0;
      width: 320px;

      li {
        width: 100%;
        font-size: 12px;
        color: #333;
        display: inline-block;
        line-height: 40px;

        div {
          float: left;
          line-height: 40px;
          font-size: 12px;
        }

        .icon {
          width: 30px;

          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }

        .name {
          width: 70px;
        }

        .content {
          width: 180px;
        }
      }
    }
  }

  .nav-title {
    font-size: 20px;
  }
}

.nav-title {
  color: #333;
  margin-bottom: 2px;
  font-family: ArialMT;
}

.warp {
  margin: 0 auto;
}

li {
  list-style-type: none;
}

.footer {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #f7f7f7;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
}

.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  background: #fff;
  box-shadow: 0px 7px 7px -7px #5e5e5e;
}

html * {
  outline: 0;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  vertical-align: top;
  outline: none;
}