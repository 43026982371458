.header .logo {
  float: left;
  font-size: 30px;
  text-align: center;
}
.menu {
  float: right;
  height: 100%;
}
.menu li {
  float: left;
  text-align: center;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.menu li.on {
  color: #9a04eb;
}
.menu li.on::after {
  content: '';
  position: absolute;
  bottom: 25px;
  left: 35px;
  width: 50px;
  height: 2px;
  background: #9a04eb;
}
.menu li:hover {
  color: #9a04eb;
}
.banner {
  position: relative;
  text-align: center;
}
.banner img {
  width: 100%;
}
.banner .tips {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #fff;
  font-weight: 400;
}
.finance-mode,
.business-mode,
.insurance-mode,
.about-mode,
.contact-mode {
  color: #999;
  text-align: center;
}
.finance-mode .title,
.business-mode .title,
.insurance-mode .title,
.about-mode .title,
.contact-mode .title {
  color: #333;
}
.finance-mode .tips,
.business-mode .tips,
.insurance-mode .tips,
.about-mode .tips,
.contact-mode .tips {
  color: #666;
}
.finance-mode img,
.business-mode img,
.insurance-mode img,
.about-mode img,
.contact-mode img {
  width: 100%;
}
.finance-mode .about-banner,
.business-mode .about-banner,
.insurance-mode .about-banner,
.about-mode .about-banner,
.contact-mode .about-banner {
  display: inline-block;
  width: 100%;
  position: relative;
}
.finance-mode .about-banner img,
.business-mode .about-banner img,
.insurance-mode .about-banner img,
.about-mode .about-banner img,
.contact-mode .about-banner img {
  width: 100%;
}
.finance-mode .about-banner .banner-list,
.business-mode .about-banner .banner-list,
.insurance-mode .about-banner .banner-list,
.about-mode .about-banner .banner-list,
.contact-mode .about-banner .banner-list {
  position: absolute;
  left: 50%;
  top: 50%;
}
.finance-mode .about-banner .banner-list li,
.business-mode .about-banner .banner-list li,
.insurance-mode .about-banner .banner-list li,
.about-mode .about-banner .banner-list li,
.contact-mode .about-banner .banner-list li {
  width: calc(100% / 3);
  float: left;
  color: #fff;
}
.finance-mode .contact-info,
.business-mode .contact-info,
.insurance-mode .contact-info,
.about-mode .contact-info,
.contact-mode .contact-info {
  margin: 50px auto;
  display: inline-block;
}
.finance-mode .contact-info .logo,
.business-mode .contact-info .logo,
.insurance-mode .contact-info .logo,
.about-mode .contact-info .logo,
.contact-mode .contact-info .logo {
  text-align: left;
}
.finance-mode .contact-info .logo p,
.business-mode .contact-info .logo p,
.insurance-mode .contact-info .logo p,
.about-mode .contact-info .logo p,
.contact-mode .contact-info .logo p {
  color: #333;
}
.finance-mode .contact-info .list,
.business-mode .contact-info .list,
.insurance-mode .contact-info .list,
.about-mode .contact-info .list,
.contact-mode .contact-info .list {
  float: right;
  text-align: left;
}
.insurance-mode .item {
  width: 25%;
  float: left;
  color: #666;
}
.about-mode {
  background: #f7f6f8;
}
.about-mode .superiority {
  display: inline-block;
  width: 100%;
}
.about-mode .superiority .item {
  width: 33.3333333%;
  float: left;
  color: #666;
  background: #fff;
  border-radius: 7px;
  border: 1px solid #ddd;
}
.business-mode {
  padding: 0 !important;
  display: inline-block;
  width: 100%;
}
.business-mode .business-warp {
  position: absolute;
}
.business-mode .business-warp .nav-title,
.business-mode .business-warp .info,
.business-mode .business-warp .tips {
  color: #fff;
}
@media screen and (min-width: 1300px) {
  .warp {
    max-width: 1200px;
  }
  .header {
    line-height: 88px;
    height: 88px;
  }
  .header .logo {
    width: 130px;
    height: 40px;
    margin: 23px 0;
  }
  .header .menu {
    font-size: 18px;
  }
  .header .menu li {
    width: 120px;
  }
  .banner p {
    margin: 0;
  }
  .banner .tips {
    width: 560px;
    height: 110px;
    margin: -55px 0 0 -280px;
    font-size: 20px;
  }
  .banner .tips .name {
    font-size: 60px;
  }
  .banner .tips .info {
    font-size: 30px;
    margin: -5px 0 10px;
  }
  .finance-mode,
  .business-mode,
  .insurance-mode,
  .about-mode,
  .contact-mode {
    padding: 60px 0;
    font-size: 16px;
    position: relative;
  }
  .finance-mode .info,
  .business-mode .info,
  .insurance-mode .info,
  .about-mode .info,
  .contact-mode .info {
    font-size: 27px;
    color: #bbb;
  }
  .finance-mode .tips,
  .business-mode .tips,
  .insurance-mode .tips,
  .about-mode .tips,
  .contact-mode .tips {
    margin: 5px 0 89px 0;
    line-height: 30px;
    padding: 0 140px;
  }
  .business-warp {
    width: 70%;
    top: 0;
    left: 50%;
    margin: 0 0 0 -35%;
    padding: 40px 0;
  }
  .insurance-mode .service .tilte {
    font-size: 18px;
  }
  .insurance-mode .service .list {
    margin: 60px 0 0;
  }
  .insurance-mode .service .list img {
    width: 41px;
    height: 41px;
    margin-bottom: 25px;
  }
  .insurance-mode .service .list .item {
    font-size: 16px;
  }
  .insurance-mode .diagram {
    padding: 80px 60px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .insurance-mode .diagram img {
    width: 576px;
    height: 486px;
    margin: 50px 0 0;
  }
  .about-mode {
    padding: 60px 0 0;
  }
  .about-mode .superiority .list {
    margin: 30px 140px 80px;
  }
  .about-mode .superiority .list .item {
    width: 230px;
    height: 160px;
    margin: 0 110px 0 0;
    padding: 30px 0 0;
    box-sizing: border-box;
    font-size: 16px;
  }
  .about-mode .superiority .list .item img {
    width: 68px;
    height: 48px;
    margin-bottom: 20px;
  }
  .about-mode .superiority .list .item:last-child {
    margin: 0;
  }
  .about-mode .ecosphere {
    padding: 80px 60px 0;
    margin: 0 0 80px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .about-mode .ecosphere img {
    width: 300px;
    height: 300px;
    margin: 50px 0 0;
  }
  .contact-info {
    width: 900px;
  }
  .contact-info .logo {
    float: left;
    margin: 20px 0;
  }
  .contact-info .logo p {
    font-size: 20px;
    margin: 10px 0;
  }
  .contact-info .logo img {
    width: 130px;
    height: 40px;
  }
  .contact-info .list {
    width: 320px;
  }
  .contact-info .list li {
    width: 100%;
    font-size: 14px;
    color: #333;
    display: inline-block;
    line-height: 40px;
  }
  .contact-info .list li div {
    float: left;
    line-height: 40px;
    font-size: 14px;
  }
  .contact-info .list li .icon {
    width: 30px;
  }
  .contact-info .list li .icon img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .contact-info .list li .name {
    width: 80px;
  }
  .contact-info .list li .content {
    width: 210px;
  }
  .nav-title {
    font-size: 32px;
  }
}
@media screen and (min-width: 750px) and (max-width: 1300px) {
  .warp {
    max-width: 800px;
  }
  .header {
    line-height: 76px;
    height: 80px;
  }
  .header .logo {
    width: 100px;
    height: 36px;
    margin: 17px;
  }
  .header .menu {
    font-size: 14px;
  }
  .header .menu li {
    width: 90px;
  }
  .header .menu li.on::after {
    bottom: 23px;
    left: 24px;
    width: 40px;
    height: 2px;
  }
  .banner p {
    margin: 0;
  }
  .banner .tips {
    width: 560px;
    height: 110px;
    margin: -55px 0 0 -280px;
    font-size: 16px;
  }
  .banner .tips .name {
    font-size: 30px;
  }
  .banner .tips .info {
    font-size: 20px;
    margin: -5px 0 10px;
  }
  .finance-mode,
  .business-mode,
  .insurance-mode,
  .about-mode,
  .contact-mode {
    padding: 60px 0;
    font-size: 14px;
    position: relative;
  }
  .finance-mode .info,
  .business-mode .info,
  .insurance-mode .info,
  .about-mode .info,
  .contact-mode .info {
    font-size: 18px;
    color: #bbb;
  }
  .finance-mode .tips,
  .business-mode .tips,
  .insurance-mode .tips,
  .about-mode .tips,
  .contact-mode .tips {
    margin: 5px 0 30px 0;
    line-height: 30px;
    padding: 0 120px;
  }
  .business-warp {
    width: 90%;
    top: 0;
    left: 50%;
    margin: 0 0 0 -45%;
    padding: 30px 0;
  }
  .business-warp img {
    padding: 0 20%;
    box-sizing: border-box;
  }
  .insurance-mode .service .tilte {
    font-size: 18px;
  }
  .insurance-mode .service .list {
    margin: 60px 0 0;
  }
  .insurance-mode .service .list img {
    width: 41px;
    height: 41px;
    margin-bottom: 25px;
  }
  .insurance-mode .service .list .item {
    font-size: 16px;
  }
  .insurance-mode .diagram {
    padding: 80px 60px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .insurance-mode .diagram img {
    width: 376px;
    height: 286px;
    margin: 50px 0 0;
  }
  .about-mode {
    padding: 60px 0 0;
  }
  .about-mode .superiority .list {
    margin: 30px 122px 80px;
  }
  .about-mode .superiority .list .item {
    width: 140px;
    height: 80px;
    margin: 0 50px 0 0;
    padding: 15px 0 0;
    box-sizing: border-box;
    font-size: 12px;
  }
  .about-mode .superiority .list .item img {
    width: 40px;
    height: 28px;
    margin-bottom: 5px;
  }
  .about-mode .superiority .list .item:last-child {
    margin: 0;
  }
  .about-mode .ecosphere {
    padding: 80px 60px 0;
    margin: 0 0 80px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .about-mode .ecosphere img {
    width: 200px;
    height: 200px;
    margin: 50px 0 0;
  }
  .contact-info {
    width: 600px;
  }
  .contact-info .logo {
    float: left;
    margin: 20px 0;
  }
  .contact-info .logo p {
    font-size: 16px;
    margin: 10px 0;
  }
  .contact-info .logo img {
    width: 110px;
    height: 30px;
  }
  .contact-info .list {
    width: 320px;
  }
  .contact-info .list li {
    width: 100%;
    font-size: 12px;
    color: #333;
    display: inline-block;
    line-height: 40px;
  }
  .contact-info .list li div {
    float: left;
    line-height: 40px;
    font-size: 12px;
  }
  .contact-info .list li .icon {
    width: 30px;
  }
  .contact-info .list li .icon img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .contact-info .list li .name {
    width: 80px;
  }
  .contact-info .list li .content {
    width: 210px;
  }
  .nav-title {
    font-size: 24px;
  }
}
@media screen and (min-width: 550px) and (max-width: 750px) {
  .warp {
    max-width: 375px;
  }
  .header {
    line-height: 51px;
    height: 55px;
  }
  .header .logo {
    width: 50px;
    height: 20px;
    margin: 12px 20px;
  }
  .header .menu {
    font-size: 12px;
  }
  .header .menu li {
    width: 55px;
  }
  .header .menu li.on::after {
    bottom: 10px;
    left: 8px;
    width: 40px;
    height: 2px;
  }
  .banner p {
    margin: 0;
  }
  .banner .tips {
    width: 375px;
    height: 110px;
    margin: -40px 0 0 -190px;
    font-size: 12px;
  }
  .banner .tips .name {
    font-size: 20px;
  }
  .banner .tips .info {
    font-size: 14px;
    margin: -5px 0 10px;
  }
  .finance-mode,
  .business-mode,
  .insurance-mode,
  .about-mode,
  .contact-mode {
    padding: 30px 0;
    font-size: 14px;
    position: relative;
  }
  .finance-mode .info,
  .business-mode .info,
  .insurance-mode .info,
  .about-mode .info,
  .contact-mode .info {
    font-size: 14px;
    color: #bbb;
  }
  .finance-mode .tips,
  .business-mode .tips,
  .insurance-mode .tips,
  .about-mode .tips,
  .contact-mode .tips {
    margin: 5px 0 29px 0;
    line-height: 24px;
    padding: 0 10px;
  }
  .finance-mode img {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .business-warp {
    width: 90%;
    top: 0;
    left: 50%;
    padding: 20px 0;
    margin: 0 0 0 -45%;
  }
  .business-warp .tips {
    font-size: 10px;
    line-height: 20px;
    margin: 15px 0 5px;
  }
  .business-warp img {
    padding: 5px 80px 0;
    box-sizing: border-box;
  }
  .insurance-mode .service .tilte {
    font-size: 14px;
  }
  .insurance-mode .service .list {
    margin: 30px 0 0;
  }
  .insurance-mode .service .list img {
    width: 31px;
    height: 31px;
    margin-bottom: 15px;
  }
  .insurance-mode .service .list .item {
    font-size: 12px;
  }
  .insurance-mode .diagram {
    padding: 40px 30px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .insurance-mode .diagram img {
    width: 196px;
    margin: 30px 0 0;
  }
  .about-mode {
    padding: 30px 0 0;
  }
  .about-mode .superiority .list {
    margin: 30px 20px 30px;
  }
  .about-mode .superiority .list .item {
    width: 100px;
    height: 80px;
    margin: 0 15px 0 0;
    padding: 15px 0 0;
    box-sizing: border-box;
    font-size: 12px;
  }
  .about-mode .superiority .list .item img {
    width: 30px;
    margin-bottom: 5px;
  }
  .about-mode .superiority .list .item:last-child {
    margin: 0;
  }
  .about-mode .ecosphere {
    padding: 40px 30px 0;
    margin: 0 0 80px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .about-mode .ecosphere img {
    width: 180px;
    height: 180px;
    margin: 30px 0 0;
  }
  .contact-info {
    width: 375px;
  }
  .contact-info .logo {
    text-align: center;
    margin: 0 auto;
    display: table;
  }
  .contact-info .logo p {
    font-size: 16px;
    margin: 10px 0;
  }
  .contact-info .logo img {
    width: 110px;
    height: 30px;
  }
  .contact-info .list {
    display: table;
    margin: 20px auto 0;
    width: 300px;
  }
  .contact-info .list li {
    width: 100%;
    font-size: 12px;
    color: #333;
    display: inline-block;
    line-height: 40px;
  }
  .contact-info .list li div {
    float: left;
    line-height: 40px;
    font-size: 12px;
  }
  .contact-info .list li .icon {
    width: 30px;
  }
  .contact-info .list li .icon img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .contact-info .list li .name {
    width: 60px;
  }
  .contact-info .list li .content {
    width: 170px;
  }
  .nav-title {
    font-size: 20px;
  }
}
@media screen and (min-width: 0) and (max-width: 550px) {
  .warp {
    max-width: 375px;
  }
  .header {
    line-height: 51px;
    height: 55px;
  }
  .header .logo {
    width: 50px;
    height: 20px;
    margin: 12px 20px;
  }
  .header .menu {
    font-size: 12px;
  }
  .header .menu li {
    width: 55px;
  }
  .header .menu li.on::after {
    bottom: 10px;
    left: 8px;
    width: 40px;
    height: 2px;
  }
  .banner p {
    margin: 0;
  }
  .banner .tips {
    width: 375px;
    height: 110px;
    margin: -40px 0 0 -190px;
    font-size: 12px;
  }
  .banner .tips .name {
    font-size: 20px;
  }
  .banner .tips .info {
    font-size: 14px;
    margin: -5px 0 10px;
  }
  .finance-mode,
  .business-mode,
  .insurance-mode,
  .about-mode,
  .contact-mode {
    padding: 30px 0;
    font-size: 14px;
    position: relative;
  }
  .finance-mode .info,
  .business-mode .info,
  .insurance-mode .info,
  .about-mode .info,
  .contact-mode .info {
    font-size: 14px;
    color: #bbb;
  }
  .finance-mode .tips,
  .business-mode .tips,
  .insurance-mode .tips,
  .about-mode .tips,
  .contact-mode .tips {
    margin: 5px 0 29px 0;
    line-height: 24px;
    padding: 0 10px;
  }
  .finance-mode img {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .business-warp {
    width: 90%;
    top: 0;
    left: 50%;
    padding: 5px 0;
    margin: 0 0 0 -45%;
  }
  .business-warp .tips {
    font-size: 10px;
    line-height: 14px;
    margin: 0 0 5px;
  }
  .business-warp img {
    padding: 0 80px;
    box-sizing: border-box;
  }
  .insurance-mode .service .tilte {
    font-size: 14px;
  }
  .insurance-mode .service .list {
    margin: 30px 0 0;
  }
  .insurance-mode .service .list img {
    width: 31px;
    height: 31px;
    margin-bottom: 15px;
  }
  .insurance-mode .service .list .item {
    font-size: 12px;
  }
  .insurance-mode .diagram {
    padding: 40px 30px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .insurance-mode .diagram img {
    width: 196px;
    margin: 30px 0 0;
  }
  .about-mode {
    padding: 30px 0 0;
  }
  .about-mode .superiority .list {
    margin: 30px 20px 30px;
  }
  .about-mode .superiority .list .item {
    width: 100px;
    height: 80px;
    margin: 0 15px 0 0;
    padding: 15px 0 0;
    box-sizing: border-box;
    font-size: 12px;
  }
  .about-mode .superiority .list .item img {
    width: 30px;
    margin-bottom: 5px;
  }
  .about-mode .superiority .list .item:last-child {
    margin: 0;
  }
  .about-mode .ecosphere {
    padding: 40px 30px 0;
    margin: 0 0 80px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  .about-mode .ecosphere img {
    width: 180px;
    height: 180px;
    margin: 30px 0 0;
  }
  .contact-info {
    width: 375px;
  }
  .contact-info .logo {
    text-align: center;
    margin: 0 auto;
    display: table;
  }
  .contact-info .logo p {
    font-size: 16px;
    margin: 10px 0;
  }
  .contact-info .logo img {
    width: 110px;
    height: 30px;
  }
  .contact-info .list {
    display: table;
    margin: 20px auto 0;
    width: 320px;
  }
  .contact-info .list li {
    width: 100%;
    font-size: 12px;
    color: #333;
    display: inline-block;
    line-height: 40px;
  }
  .contact-info .list li div {
    float: left;
    line-height: 40px;
    font-size: 12px;
  }
  .contact-info .list li .icon {
    width: 30px;
  }
  .contact-info .list li .icon img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .contact-info .list li .name {
    width: 70px;
  }
  .contact-info .list li .content {
    width: 180px;
  }
  .nav-title {
    font-size: 20px;
  }
}
.nav-title {
  color: #333;
  margin-bottom: 2px;
  font-family: ArialMT;
}
.warp {
  margin: 0 auto;
}
li {
  list-style-type: none;
}
.footer {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #f7f7f7;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
}
.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  background: #fff;
  box-shadow: 0px 7px 7px -7px #5e5e5e;
}
html * {
  outline: 0;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  vertical-align: top;
  outline: none;
}
